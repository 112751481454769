import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueSocketIO from 'vue-socket.io'
import { LMap, LTileLayer, LMarker, LPopup, LControlLayers } from 'vue2-leaflet'
import 'leaflet/dist/leaflet.css'

import { Icon } from 'leaflet'

import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import liff from '@line/liff'

liff.init({
  liffId: '1546714042-KVbdpqRk', // Use own liffId,
  withLoginOnExternalBrowser: false
}).then(async () => {
  // Start to use liff's api
  const version = await liff.getLineVersion()
  const profile = await liff.getProfile()

  if (version) window.gtag('event', 'line_version', { version: version })
  if (profile) window.gtag('event', 'line_profile', profile)
}).catch((err) => {
  console.err(err)
  // console.log(err)
  // if (!liff.isLoggedIn()) {
  //   liff.login({ redirectUri: window.location.href })
  // }
})

Vue.component('l-map', LMap)
Vue.component('l-tile-layer', LTileLayer)
Vue.component('l-marker', LMarker)
Vue.component('l-popup', LPopup)
Vue.component('l-control-layers', LControlLayers)

Vue.config.productionTip = false

Vue.use(new VueSocketIO({
  debug: false,
  connection: 'https://livemap-server.24gps.co',
  vuex: {
    store,
    actionPrefix: 'SOCKET_',
    mutationPrefix: 'SOCKET_'
  }
}))

Sentry.init({
  Vue,
  dsn: 'https://8716ee13d3e7450f9da840851e51b876@o65206.ingest.sentry.io/6092074',
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['localhost', 'my-site-url.com', /^\//]
    })
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
})

delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
